import React from 'react'
import { compose, withProps } from 'recompose'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap
} from 'react-google-maps'
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel'

const labelSize = { width: 220 }
const labelPadding = 8

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyBdE7hJARzeMvmD_yitrYKTKwG35KIZQ2Q&v=3.exp&libraries=geometry,drawing,markers',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `536px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        markers: [
            {
                lat: 50.03773418924108,
                lng: 16.50092715576527,
                id: 1,
                title: 'Kancelář Letohrad',
                label: 'Kancelář Letohrad',
                sub: "Požárníků 330/330, 561 51 Letohrad"
            },
            {
                lat: 49.20017267141386,
                lng: 16.609719909848668,
                id: 2,
                title: 'Kancelář Brno',
                label: 'Kancelář Brno',
                sub: "Koliště 1912/13, 602 00 Brno"
            },
            {
                lat: 50.099454, 
                lng: 14.397491,
                id: 3,
                title: 'Kancelář Praha',
                label: 'Kancelář Praha',
                sub: "Kafkova 605/16, 160 00 Praha 6-Dejvice"
            }           
        ],
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: 49.787489, lng: 15.753041 }}
    >
        {props.isMarkerShown &&
            props.markers.map(marker => (
                <MarkerWithLabel
                    labelStyle={{
                        textAlign: 'center',
                        width: labelSize.width + 'px',
                        backgroundColor: '#fff',
                        border: '1px solid #000',
                        fontSize: '14px',
                        padding: labelPadding + 'px',
                    }}
                    labelAnchor={{
                        x: labelSize.width / 2 + labelPadding,
                        y: 100,
                    }}
                    key={marker.id}
                    position={{ lat: marker.lat, lng: marker.lng }}
                >
                    <span><b>{marker.label}</b><br/>{marker.sub}</span>
                </MarkerWithLabel>
            ))}
    </GoogleMap>
))

class MyMap extends React.PureComponent {
    state = {
        isMarkerShown: false,
    }

    componentDidMount() {
        this.delayedShowMarker()
    }

    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({ isMarkerShown: true })
        }, 3000)
    }

    handleMarkerClick = () => {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
    }

    render() {
        return (
            <MyMapComponent
                isMarkerShown={this.state.isMarkerShown}
                onMarkerClick={this.handleMarkerClick}
            />
        )
    }
}

export default MyMap
