import * as React from "react"
import { useIntl } from "gatsby-plugin-intl"
import HomeSection from "../components/Sections/Home"
import About from "../components/Sections/About"
import Inuvio from "../components/Sections/Inuvio"
import Implementation from "../components/Sections/Implementation"
import Reference from "../components/Sections/Reference"
import Team from "../components/Sections/Team"
import Contact from "../components/Sections/Contact"
import Residence from "../components/Sections/Residence"
import News from "../components/Sections/News"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeliosXShoptet from "../components/Sections/HeliosXShoptet"
import Jobs from "../components/Sections/Jobs"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO 
        lang={intl.locale} 
        title={intl.formatMessage({ id: "title" })} 
        keywords={['helios, helios inuvio, helios erp, erp, helios orange inuvio, helios brno, erp systém, solutions, helios orange, asseco solutions, asseco']}/>
      <HomeSection/>
      <About/>
      <Inuvio/>
      <Implementation/>
      <HeliosXShoptet/>
      <News/>
      <Team/>
      <Reference/>
      <Jobs/>
      <Contact/>
      <Residence/>
    </Layout>
  )
}

export default IndexPage
