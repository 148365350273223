import React from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import Img from "gatsby-image"
import "./Reference.scss"
import noImage from "../../images/no-image.png"

const Reference = () => {
    const posts = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    name
                    position
                    reference
                    thumbnail
                }
            }
        }
    }`);
    const references = posts.allMarkdownRemark.nodes.filter(entity => entity.fields.slug.includes("/reference-") ? entity : null);
    const isInfinite = references.length > 1 ? true : false;
    const settings = {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: isInfinite,
        responsive: [
        {
            breakpoint: 992,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            },
        },
        ],
    }
    return (
        <section id="reference" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="reference.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center title-description">
                    <div className="col-12  col-lg-8 text-center">
                        <FormattedHTMLMessage className="text-regular" id="reference.text" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    {/*<Iframe url="https://coalsoft.cz/cs/#reference"
                        width="350px"
                        className="coalsoft-frame"
                        display="initial"
                        position="relative"
                        section="reference"
                        scrolling="no"></Iframe>*/}
                    <Slider className="coal-slick" {...settings}>
                        {references.map(reference => {
                            const name = reference.frontmatter.name;
                            const position = reference.frontmatter.position;
                            const body = reference.frontmatter.reference;
                            var image = reference.frontmatter.thumbnail || noImage;
                            var splitPath = image.split("static/");
                            if (splitPath.length > 1) {
                                image = splitPath[0] + splitPath[1]
                            }
                            return (
                            <div className="reference-box">
                                <div className="img-container">
                                <div className="imgBox">
                                    <img className="rounded-circle shadow ref-img" src={image}></img>
                                </div>
                                </div>
                                <div className="reference-header">
                                    <h4 className="reference-title">{name}</h4>
                                    <h5 className="reference-subtitle text-muted">{position}</h5>
                                </div>
                                <div className="reference-text">
                                    <p>
                                        <span className="quote-left"><i className="fas fa-quote-left calouser-icon"></i></span>
                                        {body}
                                        <span className="quote-right"><i className="fas fa-quote-right calouser-icon"></i></span>
                                    </p>
                                </div>
                            </div>
                        )})}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Reference