import React from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import shoptetImg from "../../images/shoptet-diagram.png";
import "./HeliosXShoptet.scss"

const HeliosXShoptet = () => {
    return (
        <section id="heliosxshoptet" className="page-section background-gray">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="heliosxshoptet.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-2 justify-content-center title-description">
                    <div className="col-12 col-lg-8 text-center">
                        <p className="title-description">
                            <FormattedHTMLMessage className="" id="heliosxshoptet.promoText" />
                        </p>
                    </div>
                </div>
                <div className="row pb-2 justify-content-center title-description">
                    <div className="col-12 col-lg-8 text-center">
                        <img className="diagram" src={shoptetImg}></img>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeliosXShoptet