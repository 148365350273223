import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import "./Team.scss"

const Team = () => {
    const employees = useStaticQuery(graphql`
    query {
      uhlir: file(relativePath: { eq: "people/uhlir.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sehnoutka: file(relativePath: { eq: "people/sehnoutka.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cvejn: file(relativePath: { eq: "people/cvejn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dockalova: file(relativePath: { eq: "people/dockalova.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cervinka: file(relativePath: { eq: "people/cervinka.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kachlik: file(relativePath: { eq: "people/kachlik.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kalat: file(relativePath: { eq: "people/kalat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nouser: file(relativePath: { eq: "people/nouser.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kaspar: file(relativePath: { eq: "people/kaspar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`)

    return (
        <section id="team" className="page-section background-gray">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="team.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12  col-lg-8 text-center title-description-center">
                        <FormattedHTMLMessage id="team.text" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="card-team">
                        <div className="imgBox">
                            <Img fluid={employees.uhlir.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Michal Uhlíř</h5>
                                <h6 className="card-subtitle text-muted">Jednatel společnosti</h6>
                                <div className="contact-section">
                                    <a href="mailto:michal@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>michal@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-team">
                        <div className="imgBox">
                            <Img fluid={employees.kalat.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Jakub Kalát</h5>
                                <h6 className="card-subtitle text-muted">Výkonný ředitel</h6>
                                <div className="contact-section">
                                    <a href="mailto:jakub.kalat@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>jakub.kalat@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-team">
                        <div className="imgBox">
                            <Img fluid={employees.cervinka.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Filip Červinka</h5>
                                <h6 className="card-subtitle text-muted">Marketingový ředitel</h6>
                                <div className="contact-section">
                                    <a href="mailto:filip.cervinka@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>filip.cervinka@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-team d-flex flex-row">
                        <div className="imgBox">
                            <Img fluid={employees.kaspar.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Michal Kašpar</h5>
                                <h6 className="card-subtitle text-muted">Produktový manažer</h6>
                                <div className="contact-section">
                                <p className="user-mobile"><i className="fa fa-phone-square fa-2x contact-icon" aria-hidden="true"></i>+420 602 600 344</p>
                                    <a href="mailto:michal.kaspar@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>michal.kaspar@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-team">
                        <div className="imgBox">
                            <Img fluid={employees.sehnoutka.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Adam Sehnoutka</h5>
                                <h6 className="card-subtitle text-muted">HELIOS konzultant</h6>
                                <div className="contact-section">
                                    <a href="mailto:adam@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>adam@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-team d-flex flex-row">
                        <div className="imgBox">
                            <Img fluid={employees.cvejn.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Jakub Cvejn</h5>
                                <h6 className="card-subtitle text-muted">HELIOS konzultant</h6>
                                <div className="contact-section">
                                    <a href="mailto:jakub@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>jakub@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-team d-flex flex-row">
                        <div className="imgBox">
                            <Img fluid={employees.dockalova.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Markéta Dočkalová</h5>
                                <h6 className="card-subtitle text-muted">HELIOS konzultant</h6>
                                <div className="contact-section">
                                    <a href="mailto:marketa@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>marketa@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-team d-flex flex-row">
                        <div className="imgBox">
                            <Img fluid={employees.kachlik.childImageSharp.fluid} className="rounded-circle shadow"/>
                        </div>
                        <div className="align-items-center user-info">
                            <div className="ml-4 w-100">
                                <h5 className="card-title">Stanislav Kachlík</h5>
                                <h6 className="card-subtitle text-muted">HELIOS konzultant</h6>
                                <div className="contact-section">
                                    <a href="mailto:stanislav@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>stanislav@coalios.cz</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team