import React, {useState} from "react";
import { graphql, useStaticQuery } from "gatsby"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import Carousel from "react-bootstrap/Carousel"
import Img from "gatsby-image"
import "./Home.scss"

import heliosLogo from "../../images/helios-partner-inverse.png"
import shoptetLogo from "../../images/shoptet-partner-inverse.png"

const Home = () => {
    const data = useStaticQuery(graphql`
    query {
      imgOne: file(relativePath: { eq: "people-one.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgTwo: file(relativePath: { eq: "people-two.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgThree: file(relativePath: { eq: "people-three.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgFour: file(relativePath: { eq: "people-four.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`)

    return (
        <section id="home" className="">
          <Carousel fade={true} pause={false}>
            <Carousel.Item interval={8000}>
              <Img fluid={data.imgOne.childImageSharp.fluid} loading="eager" />
              <Carousel.Caption>
                <div className="logoTitle">
                  <img className="heliosLogo" src={heliosLogo}/>
                  <h3 className="imageTitle">coalios s.r.o. Váš partner pro systém HELIOS iNuvio</h3>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={8000}>
              <Img fluid={data.imgFour.childImageSharp.fluid} loading="eager" />
              <Carousel.Caption>
                <div className="logoTitle">
                  <img className="heliosLogo" src={shoptetLogo}/>
                  <h3 className="imageTitle">coalios s.r.o. Váš partner pro napojení Shoptetu na HELIOS iNuvio</h3>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={8000}>
              <Img fluid={data.imgTwo.childImageSharp.fluid} loading="eager" />
              <Carousel.Caption>
                <div className="logoTitle">
                  <img className="heliosLogo" src={heliosLogo}/>
                  <h3 className="imageTitle">coalios s.r.o. Váš partner pro systém HELIOS iNuvio</h3>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={8000}>
              <Img fluid={data.imgThree.childImageSharp.fluid} loading="eager" />
              <Carousel.Caption>
                <div className="logoTitle">
                  <img className="heliosLogo" src={heliosLogo}/>
                  <h3 className="imageTitle">coalios s.r.o. Váš partner pro systém HELIOS iNuvio</h3>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </section>
    )
}

export default Home
