import React, { useState } from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import { Alert } from "react-bootstrap"
import axios from "axios"
import "./Contact.scss"

const Contact = () => {
    const [userContact, setUserContact] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [checkGdpr, setCheckGdpr] = useState(false);
    const [successAlert, setSuccessAlert] = React.useState({
        text: 'contact.alert.noData',
        show: false,
    });
    const [warningAlert, setWarningAlert] = React.useState({
        text: 'contact.alert.messageSent',
        show: false,
    });
    
    const sendMail = () => {
        if (!emailSent) {
            var mailRegex = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");
            var phoneRegex = new RegExp("^[+]?[()/0-9]{9,}$");
            var canSend = true;

            if (userContact.length < 1 && userMessage.length < 1) {
                setWarningAlert({text: "contact.alert.noData", show: true});
                canSend = false;
            } else {
                if (userContact.includes("@")) {
                    if (!mailRegex.test(userContact)) {
                        canSend = false;
                        setWarningAlert({text: "contact.alert.badEmail", show: true});
                    }
                } else if (!phoneRegex.test(userContact)) {
                    canSend = false;
                    setWarningAlert({text: "contact.alert.badNumber", show: true});
                }
                
                if (userMessage.length < 10) {
                    canSend = false;
                    setWarningAlert({text: "contact.alert.shortMessage", show: true});
                }
                
                if (!checkGdpr) {
                    canSend = false;
                    setWarningAlert({text: "contact.alert.gdpr", show: true});
                }
            }

            if (canSend) {
                var message = "<b>User contact:</b> " + userContact + "<br><b>Message from user:</b> " + userMessage;
                axios({
                    method: "post",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Basic Y29hbGlvczpNYWtpbmExMDE="
                    },
                    url: "/api/api/auth/sendMail",
                    data: {
                        message: message
                    }
                }).then(result => {
                    if (result.data.sent) {
                        setSuccessAlert({text: "contact.alert.messageSent", show: true});
                        setWarningAlert({text: "contact.alert.emailNotSent", show: false});
                        setEmailSent(true);
                    } else {
                        setWarningAlert({text: "contact.alert.emailNotSent", show: true});
                        setSuccessAlert({text: "contact.alert.messageSent", show: false});
                    }
                }).catch(error => setWarningAlert({text: "contact.alert.emailNotSent", show: false}));
            }
        }
    };

    return (
        <section id="contact-section" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="contact.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center title-description">
                    <div className="col-12 pb-5 col-lg-8 text-center">
                        <p className="title-description">
                            <FormattedHTMLMessage className="text-regular" id="contact.text" />
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="info-box contact-label">
                        <p className="user-contact"><i className="fa fa-phone-square fa-2x contact-icon" aria-hidden="true"></i>+420 602 326 793</p>
                        <a href="mailto:info@coalios.cz" className="user-email"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>info@coalios.cz</a>
                        <div className="contact-icons">
                        <a
                            className="social-icon"
                            href="https://www.facebook.com/coalfamily"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-facebook-square"></i>
                        </a>
                        <a
                            className="social-icon"
                            href="https://www.instagram.com/coalfamily_/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a
                            className="social-icon"
                            href="https://www.linkedin.com/company/coalios/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fab fa-linkedin"></i>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="row justify-content-center" style={{display: emailSent === true ? "none" : ""}}>
                    <div className="contact-box contact-label">
                        <FormattedHTMLMessage id="contact.field1" />
                        <input onChange={event => setUserContact(event.target.value)} placeholder="" type="text" className="input-field"/>
                        <FormattedHTMLMessage id="contact.field2" />
                        <textarea onChange={event => setUserMessage(event.target.value)} type="text" className="textarea-field"></textarea>
                        <div className="d-flex flex-row gdpr-panel"> 
                            <input type="checkbox" onChange={(e) => setCheckGdpr(e.currentTarget.checked)} className="gdpr-checkbox"/>
                            <FormattedHTMLMessage id="contact.gdpr" />
                        </div>
                        <button type="button" className="btn" onClick={sendMail}>
                            <FormattedHTMLMessage id="contact.send" />
                        </button>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <Alert className="custom-alert" variant="success" style={{display: successAlert.show === true ? "" : "none"}}>
                        <FormattedMessage id={successAlert.text} />
                    </Alert>
                    <Alert className="custom-alert" variant="warning" style={{display: warningAlert.show === true ? "" : "none"}}>
                        <FormattedMessage id={warningAlert.text} />
                        <button type="button" className="closeBtn" onClick={() => setWarningAlert({text: "contact.alert.noData", show: false})}>
                            <i className="fas fa-times"></i>
                        </button>
                    </Alert>
                </div>
            </div>
        </section>
    )
}

export default Contact