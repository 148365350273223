import React from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import "./About.scss"

const About = () => {
    return (
        <section id="about" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="about.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center title-description">
                    <div className="col-12 col-lg-8 text-center">
                        <p className="title-description">
                            <FormattedHTMLMessage className="" id="about.text" />
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <article className="skill-about">
                        <div className="skill-icon">
                            <i className="fas fa-user-graduate fa-5x"></i>
                        </div>
                        <h3 className="skill-title">
                            <FormattedMessage id="about.values.first.title" />
                        </h3>
                        <p className="basic-text">
                            <FormattedHTMLMessage id="about.values.first.text" />
                        </p>
                    </article>
                    <article className="skill-about">
                        <div className="skill-icon">
                            <i className="fas fa-id-card fa-5x"></i>
                        </div>
                        <h3 className="skill-title">
                            <FormattedMessage id="about.values.second.title" />
                        </h3>
                        <p className="basic-text">
                            <FormattedHTMLMessage id="about.values.second.text" />
                        </p>
                    </article>
                    <article className="skill-about">
                        <div className="skill-icon">
                            <i className="fas fa-handshake fa-5x"></i>
                        </div>
                        <h3 className="skill-title">
                            <FormattedMessage id="about.values.third.title" />
                        </h3>
                        <p className="basic-text">
                            <FormattedHTMLMessage id="about.values.third.text" />
                        </p>
                    </article>
                </div>
            </div>
        </section>
    )
}

export default About